import React, { useEffect } from "react";
import { Helmet } from "react-helmet";

import {
  FullWidthColumnWrapper,
  SmallColumnWidthWrapper
} from "src/components/confines-wrappers";
import { CtaButton } from "src/components/cta";
import Footer from "src/components/footer";
import Header from "src/components/header";
import HelmetWrapper from "src/components/helmet-wrapper";
import { PageTopCustom } from "src/components/home-hero/home-hero";
import {
  breadCrumbSchema,
  localBusinessSchema,
  organizationSchema
} from "src/components/html-schema-snippets";
import Layout from "src/components/layout";
import PageTop from "src/components/page-top";
import { GravitywellTheme } from "src/config/theme";
import Planning from "src/assets/images/incubator/planning.jpg";

const Incubator = () => {
  const breadcrumbsSchemaData = breadCrumbSchema([
    {
      name: "Incubator",
      path: "/incubator"
    }
  ]);

  const schemaJson = [
    localBusinessSchema,
    organizationSchema,
    breadcrumbsSchemaData
  ];

  // Load Tally embeds
  useEffect(() => {
    if (window.Tally) {
      window.Tally.loadEmbeds();
    }
  }, []);

  return (
    <Layout>
      <>
        <HelmetWrapper
          canonicalPath="/incubator"
          title="Incubator"
          description="Supporting small businesses"
          updatedAt={Date.now().toString()}
        >
          <script type="application/ld+json">
            {JSON.stringify(schemaJson)}
          </script>
        </HelmetWrapper>

        <header role="banner">
          <Header
            initialLogo
            initialTheme="dark"
          />

          <PageTopCustom id="hero">
            <PageTop
              style={{
                paddingTop: 0,
                paddingBottom: 0
              }}
              height="medium"
              backgroundColor="orange"
              backgroundImage={Planning}
            >
              <FullWidthColumnWrapper
                className="top-content"
              >
                <p
                  className="heading"
                >
                  Our Incubator Program
                </p>

                <h2 style={{ color: "white" }}>
                  Empowering early-stage founders with hands-on, practical support
                </h2>
              </FullWidthColumnWrapper>
            </PageTop>
          </PageTopCustom>
        </header>

        <main
          id="main-content"
          role="main"
        >
          <h1 className="off-screen">
            Our Incubator Program
          </h1>

          <SmallColumnWidthWrapper
            style={{
              marginTop: GravitywellTheme.sizes.spacing.xxl,
              marginBottom: GravitywellTheme.sizes.spacing.xxl
            }}
          >
            <p>
              We know that there's already loads of incubator programs out there.
            </p>

            <p>
              But we’ve found that a lot of these programs are very rigid - requiring founders to commit to weeks or months of scheduled talks and workshops. And they’re often missing one thing that investors love to see - a first version of the actual product.
            </p>

            <p>
              So we wondered - could there be another way? A way that we can give founders the support and guidance they need, but
              {" "}

              <b style={{ color: GravitywellTheme.color.brandOnePrimary }}>
                also
              </b>

              {" "}
              work on building a proof-of-concept, prototype or MVP?
            </p>

            <p style={{ marginBottom: "3rem" }}>
              We’re proud to launch the Gravitywell Incubator - a pragmatic, tailored program to drive your tech start-up forward.
            </p>

            <CtaButton
              type="primary"
              text="Apply now"
              href="https://tally.so/r/mO4XPg"
            />
          </SmallColumnWidthWrapper>

          <SmallColumnWidthWrapper
            style={{
              marginTop: GravitywellTheme.sizes.spacing.xxl,
              marginBottom: GravitywellTheme.sizes.spacing.xxl
            }}
          >
            <h2>
              A program as unique as your start-up
            </h2>

            <p>
              Before we - and you - commit to anything, we want to get on the same page about where you are currently and your vision for the business. We value in-person sessions as a great way to break the ice, show you our office and introduce you to our team!
            </p>

            <p>
              During these workshops, we’ll delve into the details of your business to establish the best next steps for you. We’ll cover aspects of market research, marketing, wireframing, pitch prep and more - to understand what you’ve done already and where you might need support.
            </p>

          </SmallColumnWidthWrapper>

          <FullWidthColumnWrapper
            style={{
              paddingTop: GravitywellTheme.sizes.spacing.xxl,
              paddingBottom: GravitywellTheme.sizes.spacing.xxl,
              backgroundColor: GravitywellTheme.color.lightestGrey
            }}
          >

            <SmallColumnWidthWrapper
              style={{
                marginTop: GravitywellTheme.sizes.spacing.xxl,
                marginBottom: GravitywellTheme.sizes.spacing.xxl
              }}
            >
              <h2 style={{ fontSize: GravitywellTheme.font.size.display_1 }}>
                Bring it to life
              </h2>

              <p style={{ fontSize: GravitywellTheme.font.size.body_2 }}>
                We know that sometimes, a prototype, proof of concept or MVP is what stands between startups and potential investment - and Gravitywell is uniquely positioned to serve this space.
              </p>

              <p
                style={{
                  marginBottom: "3rem",
                  fontSize: GravitywellTheme.font.size.body_2
                }}
              >
                At the heart of our incubator program is the first build of your product. This is a pared-back, initial version which demonstrates the potential and market value of your business - perhaps by allowing you to launch to an initial user base or show the potential of a certain technology to investors.
              </p>

              <CtaButton
                type="primary"
                text="Let's do it"
                href="https://tally.so/r/mO4XPg"
              />

            </SmallColumnWidthWrapper>
          </FullWidthColumnWrapper>

          <SmallColumnWidthWrapper
            style={{
              marginTop: GravitywellTheme.sizes.spacing.xxl,
              marginBottom: GravitywellTheme.sizes.spacing.xxl
            }}
          >
            <h2>
              A build-your-own support program
            </h2>

            <p>
              In the initial discussions, we’ll have offered a variety of consultancy - and there might be areas where you’d like more support. Rather than dictating what you can and can’t have, we’ve designed the incubator with a build-your-own approach to these offerings, so you can pick where your time and money are best spent.
            </p>

            <p>
              Incubator clients get
              {" "}

              <b style={{ color: GravitywellTheme.color.brandOnePrimary }}>
                30 hours
                of consultancy
                {" "}

              </b>

              {" "}
              included in the program - worth over £4000 - and we’ll advise you on where we think this time would be best spent.

            </p>

            <p>
              If you need additional time with our team, you’ll get a reduced hourly rate on these services.
            </p>

            <p>
              If there’s anything else that you’re looking for, let us know - we can probably help! We have a strong network of partners we can refer you to for anything we can’t directly provide - and we’re happy to look over quotes and proposals for other suppliers you work with.
            </p>

          </SmallColumnWidthWrapper>

          <FullWidthColumnWrapper
            style={{
              display: "flex",
              flexWrap: "wrap",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "end",
              gap: "2rem"
            }}
          >
            <div style={{ textAlign: "center" }}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 640 512"
                height="4rem"
              >
                <path d="M192 96a48 48 0 1 0 0-96 48 48 0 1 0 0 96zm-8 384V352h16V480c0 17.7 14.3 32 32 32s32-14.3 32-32V192h56 64 16c17.7 0 32-14.3 32-32s-14.3-32-32-32H384V64H576V256H384V224H320v48c0 26.5 21.5 48 48 48H592c26.5 0 48-21.5 48-48V48c0-26.5-21.5-48-48-48H368c-26.5 0-48 21.5-48 48v80H243.1 177.1c-33.7 0-64.9 17.7-82.3 46.6l-58.3 97c-9.1 15.1-4.2 34.8 10.9 43.9s34.8 4.2 43.9-10.9L120 256.9V480c0 17.7 14.3 32 32 32s32-14.3 32-32z" />
              </svg>

              <p>
                Pitch decks & pitches
              </p>
            </div>

            <div style={{ textAlign: "center" }}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="3rem"
                viewBox="0 0 512 512"
              >
                <path d="M128 32h32c17.7 0 32 14.3 32 32V96H96V64c0-17.7 14.3-32 32-32zm64 96V448c0 17.7-14.3 32-32 32H32c-17.7 0-32-14.3-32-32V388.9c0-34.6 9.4-68.6 27.2-98.3C40.9 267.8 49.7 242.4 53 216L60.5 156c2-16 15.6-28 31.8-28H192zm227.8 0c16.1 0 29.8 12 31.8 28L459 216c3.3 26.4 12.1 51.8 25.8 74.6c17.8 29.7 27.2 63.7 27.2 98.3V448c0 17.7-14.3 32-32 32H352c-17.7 0-32-14.3-32-32V128h99.8zM320 64c0-17.7 14.3-32 32-32h32c17.7 0 32 14.3 32 32V96H320V64zm-32 64V288H224V128h64z" />
              </svg>

              <p>
                Technical scoping
              </p>
            </div>

            <div style={{ textAlign: "center" }}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 384 512"
                height="4rem"
              >
                <path d="M192 0c-41.8 0-77.4 26.7-90.5 64H64C28.7 64 0 92.7 0 128V448c0 35.3 28.7 64 64 64H320c35.3 0 64-28.7 64-64V128c0-35.3-28.7-64-64-64H282.5C269.4 26.7 233.8 0 192 0zm0 64a32 32 0 1 1 0 64 32 32 0 1 1 0-64zM105.8 229.3c7.9-22.3 29.1-37.3 52.8-37.3h58.3c34.9 0 63.1 28.3 63.1 63.1c0 22.6-12.1 43.5-31.7 54.8L216 328.4c-.2 13-10.9 23.6-24 23.6c-13.3 0-24-10.7-24-24V314.5c0-8.6 4.6-16.5 12.1-20.8l44.3-25.4c4.7-2.7 7.6-7.7 7.6-13.1c0-8.4-6.8-15.1-15.1-15.1H158.6c-3.4 0-6.4 2.1-7.5 5.3l-.4 1.2c-4.4 12.5-18.2 19-30.6 14.6s-19-18.2-14.6-30.6l.4-1.2zM160 416a32 32 0 1 1 64 0 32 32 0 1 1 -64 0z" />
              </svg>

              <p>
                User research
              </p>
            </div>

            <div style={{ textAlign: "center" }}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="4rem"
                viewBox="0 0 448 512"
              >
                <path d="M96 151.4V360.6c9.7 5.6 17.8 13.7 23.4 23.4H328.6c0-.1 .1-.2 .1-.3l-4.5-7.9-32-56 0 0c-1.4 .1-2.8 .1-4.2 .1c-35.3 0-64-28.7-64-64s28.7-64 64-64c1.4 0 2.8 0 4.2 .1l0 0 32-56 4.5-7.9-.1-.3H119.4c-5.6 9.7-13.7 17.8-23.4 23.4zM384.3 352c35.2 .2 63.7 28.7 63.7 64c0 35.3-28.7 64-64 64c-23.7 0-44.4-12.9-55.4-32H119.4c-11.1 19.1-31.7 32-55.4 32c-35.3 0-64-28.7-64-64c0-23.7 12.9-44.4 32-55.4V151.4C12.9 140.4 0 119.7 0 96C0 60.7 28.7 32 64 32c23.7 0 44.4 12.9 55.4 32H328.6c11.1-19.1 31.7-32 55.4-32c35.3 0 64 28.7 64 64c0 35.3-28.5 63.8-63.7 64l-4.5 7.9-32 56-2.3 4c4.2 8.5 6.5 18 6.5 28.1s-2.3 19.6-6.5 28.1l2.3 4 32 56 4.5 7.9z" />
              </svg>

              <p>
                Wireframing
              </p>
            </div>

            <div style={{ textAlign: "center" }}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="3rem"
                viewBox="0 0 512 512"
              >
                <path d="M0 32C0 14.3 14.3 0 32 0H160c17.7 0 32 14.3 32 32V416c0 53-43 96-96 96s-96-43-96-96V32zM223.6 425.9c.3-3.3 .4-6.6 .4-9.9V154l75.4-75.4c12.5-12.5 32.8-12.5 45.3 0l90.5 90.5c12.5 12.5 12.5 32.8 0 45.3L223.6 425.9zM182.8 512l192-192H480c17.7 0 32 14.3 32 32V480c0 17.7-14.3 32-32 32H182.8zM128 64H64v64h64V64zM64 192v64h64V192H64zM96 440a24 24 0 1 0 0-48 24 24 0 1 0 0 48z" />
              </svg>

              <p>
                Brand workshops
              </p>
            </div>

            <div style={{ textAlign: "center" }}>

              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="3rem"
                viewBox="0 0 512 512"
              >
                <path d="M156.6 384.9L125.7 354c-8.5-8.5-11.5-20.8-7.7-32.2c3-8.9 7-20.5 11.8-33.8L24 288c-8.6 0-16.6-4.6-20.9-12.1s-4.2-16.7 .2-24.1l52.5-88.5c13-21.9 36.5-35.3 61.9-35.3l82.3 0c2.4-4 4.8-7.7 7.2-11.3C289.1-4.1 411.1-8.1 483.9 5.3c11.6 2.1 20.6 11.2 22.8 22.8c13.4 72.9 9.3 194.8-111.4 276.7c-3.5 2.4-7.3 4.8-11.3 7.2v82.3c0 25.4-13.4 49-35.3 61.9l-88.5 52.5c-7.4 4.4-16.6 4.5-24.1 .2s-12.1-12.2-12.1-20.9V380.8c-14.1 4.9-26.4 8.9-35.7 11.9c-11.2 3.6-23.4 .5-31.8-7.8zM384 168a40 40 0 1 0 0-80 40 40 0 1 0 0 80z" />
              </svg>

              <p>
                Marketing & launch
              </p>
            </div>

            <div style={{ textAlign: "center" }}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="3rem"
                viewBox="0 0 512 512"
              >
                <path d="M320 96H192L144.6 24.9C137.5 14.2 145.1 0 157.9 0H354.1c12.8 0 20.4 14.2 13.3 24.9L320 96zM192 128H320c3.8 2.5 8.1 5.3 13 8.4C389.7 172.7 512 250.9 512 416c0 53-43 96-96 96H96c-53 0-96-43-96-96C0 250.9 122.3 172.7 179 136.4l0 0 0 0c4.8-3.1 9.2-5.9 13-8.4zm84 88c0-11-9-20-20-20s-20 9-20 20v14c-7.6 1.7-15.2 4.4-22.2 8.5c-13.9 8.3-25.9 22.8-25.8 43.9c.1 20.3 12 33.1 24.7 40.7c11 6.6 24.7 10.8 35.6 14l1.7 .5c12.6 3.8 21.8 6.8 28 10.7c5.1 3.2 5.8 5.4 5.9 8.2c.1 5-1.8 8-5.9 10.5c-5 3.1-12.9 5-21.4 4.7c-11.1-.4-21.5-3.9-35.1-8.5c-2.3-.8-4.7-1.6-7.2-2.4c-10.5-3.5-21.8 2.2-25.3 12.6s2.2 21.8 12.6 25.3c1.9 .6 4 1.3 6.1 2.1l0 0 0 0c8.3 2.9 17.9 6.2 28.2 8.4V424c0 11 9 20 20 20s20-9 20-20V410.2c8-1.7 16-4.5 23.2-9c14.3-8.9 25.1-24.1 24.8-45c-.3-20.3-11.7-33.4-24.6-41.6c-11.5-7.2-25.9-11.6-37.1-15l0 0-.7-.2c-12.8-3.9-21.9-6.7-28.3-10.5c-5.2-3.1-5.3-4.9-5.3-6.7c0-3.7 1.4-6.5 6.2-9.3c5.4-3.2 13.6-5.1 21.5-5c9.6 .1 20.2 2.2 31.2 5.2c10.7 2.8 21.6-3.5 24.5-14.2s-3.5-21.6-14.2-24.5c-6.5-1.7-13.7-3.4-21.1-4.7V216z" />
              </svg>

              <p>
                Investment & grants
              </p>
            </div>
          </FullWidthColumnWrapper>

          <SmallColumnWidthWrapper
            style={{
              marginTop: GravitywellTheme.sizes.spacing.xxl,
              marginBottom: GravitywellTheme.sizes.spacing.xxl
            }}
          >
            <h2>
              Flexible funding options
            </h2>

            <p>
              We understand that before you’ve secured investment, spending money on building anything can be a stretch - so we want to make sure there’s funding options for our incubator that are flexible and inclusive.
            </p>

            <p style={{ marginBottom: "2rem" }}>
              Our payment structure allows you the choice between paying in cash or equity - or a combination of the two. If an equity or pay-later model sounds like it’d work best for part of your funding, let us know - we’re happy to discuss the options in more detail.
            </p>
          </SmallColumnWidthWrapper>

          <SmallColumnWidthWrapper
            style={{
              marginTop: GravitywellTheme.sizes.spacing.xxl,
              marginBottom: GravitywellTheme.sizes.spacing.xxl
            }}
          >
            <h1
              style={{
                color: "#ed6f2d",
                fontSize: GravitywellTheme.font.size.display_1
              }}
            >
              Ready to get started?
            </h1>

            <p>
              Just fill out our application form and we'll be in touch.
            </p>

            <div className="form-container">
              <Helmet>
                <script src="https://tally.so/widgets/embed.js" />
              </Helmet>

              <iframe
                data-tally-src="https://tally.so/embed/mO4XPg?alignLeft=1&hideTitle=1&transparentBackground=1&dynamicHeight=1"
                loading="lazy"
                width="100%"
                height="762"
                frameBorder="0"
                title="Gravitywell Incubator Application Form"
              />
            </div>
          </SmallColumnWidthWrapper>
        </main>

        <footer role="contentinfo">
          <Footer />
        </footer>
      </>
    </Layout>
  );
};

export default Incubator;